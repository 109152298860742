import { gsap } from "gsap/gsap-core";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
var HomeAnimation = /** @class */ (function () {
    function HomeAnimation() {
        this.animationHomePage();
    }
    HomeAnimation.prototype.animationHomePage = function () {
        var layerTL = gsap.timeline({
            ease: "power4.inOut",
            stagger: 0.2,
            scrollTrigger: {
                trigger: ".wrapper--why-yper",
                start: 'top-=40% top+=20%',
                end: "bottom bottom-=20%",
            }
        });
        layerTL.to(".mask--animation", { y: 400 })
            .to(".mask--animation2", { y: 500 }, "-=0.25");
        var MockupTL = gsap.timeline({
            ease: "power4.inOut",
            scrollTrigger: {
                trigger: ".wrapper--quotation_content",
                start: 'top+=10% top+=60%',
                end: "bottom bottom-=20%",
            }
        });
        MockupTL.to(".yper--notification", { y: 0, autoAlpha: 1 })
            .to(".mockup--mac", { y: 0, autoAlpha: 1 }, "-=0.75")
            .to(".mockup--device", { y: 0, autoAlpha: 1 }, "-=0.75");
        var img = gsap.timeline({
            ease: "power4.inOut",
            scrollTrigger: {
                trigger: ".animation--content",
                start: 'top+=10% top+=60%',
                end: "bottom bottom-=20%",
            }
        });
        img.to(".img--chat", { y: 0, autoAlpha: 1 });
    };
    return HomeAnimation;
}());
export { HomeAnimation };

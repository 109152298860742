export var weRecommandSlider = {
    centerMode: false,
    arrows: true,
    slidesToShow: 4,
    infinite: false,
    prevArrow: $(".arrow--back"),
    nextArrow: $(".arrow--forward"),
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 1400,
            settings: {
                centerMode: false,
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 920,
            settings: {
                centerMode: false,
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 800,
            settings: {
                centerMode: false,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 360,
            settings: {
                centerMode: false,
                slidesToShow: 1
            }
        }
    ]
};
export var yperRecommandationSlider = {
    centerMode: false,
    arrows: false,
    infinite: false,
    slidesToShow: 2,
    responsive: [
        {
            breakpoint: 920,
            settings: {
                infinite: false,
                centerMode: false,
                slidesToShow: 1
            }
        }
    ]
};
export var deliverSlider = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    prevArrow: $(".arrow--back"),
    nextArrow: $(".arrow--forward"),
    arrow: true,
    responsive: [
        {
            breakpoint: 9999,
            settings: {
                prevArrow: $(".arrow--back"),
                nextArrow: $(".arrow--forward"),
                autoplay: false,
                infinite: true,
                centerMode: false,
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 620,
            settings: {
                settings: 'unslick',
            }
        }
    ]
};
export var sliderSlick = {
    infinite: false,
    prevArrow: $(".arrow--back"),
    nextArrow: $(".arrow--forward"),
    slidesToShow: 2,
    responsive: [
        {
            breakpoint: 9999,
            settings: 'unslick',
        },
        {
            breakpoint: 1200,
            settings: {
                arrows: true,
                slidesToShow: 1
            }
        }
    ]
};
export var sliderSlick4 = {
    centerMode: false,
    arrows: true,
    slidesToShow: 4,
    infinite: false,
    prevArrow: $(".arrow--back"),
    nextArrow: $(".arrow--forward"),
    responsive: [
        {
            breakpoint: 9999,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                centerMode: false,
                slidesToShow: 1
            }
        }
    ]
};
export var sliderHomePage = {
    centerMode: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: $(".arrow--back_pro"),
    nextArrow: $(".arrow--forward_pro"),
    responsive: [
        {
            breakpoint: 1900,
            settings: {
                centerMode: false,
                slidesToShow: 3
            }
        },
        {
            breakpoint: 992,
            settings: {
                centerMode: false,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 650,
            settings: {
                arrows: false,
                centerMode: false,
                slidesToShow: 1
            }
        }
    ]
};

import SlickSliderHelper from "@yper-script/helpers/slick_slider_helper";
import { sliderHomePage } from "@yper-script/components/utils/slick_slider_conf";
import { LottieHelper } from "yper-js-libs";
import { HomeAnimation } from "@yper-script/components/home/homeAnimation";
require('@yper-style/components/home/home.scss');
require('slick-carousel');
var Home = /** @class */ (function () {
    function Home() {
        this.homeAnimation = new HomeAnimation();
        /** JqueryElem */
        this.$shopperSpace = $(".shopper--space");
        this.$riderSpace = $(".riders--space");
        this.$hubSpace = $(".hubs--space");
        this.$shopperMap = $("#shopper--map");
        this.$riderMap = $("#rider--map");
        this.$hubMap = $("#hub--map");
        /** Helper */
        this.lottieHelperShopper = new LottieHelper("shopper--map", {
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "../../json/anim-map_pa_shopper.json"
        });
        this.lottieHelperRider = new LottieHelper("rider--map", {
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "../../json/anim-map_pa_rider.json"
        });
        this.lottieHelperHub = new LottieHelper("hub--map", {
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "../../json/anim-map_pa_hub.json"
        });
        this.slickSliderHelper = new SlickSliderHelper($(".wrapper--slider"), sliderHomePage);
        this.showLottieOnclick();
        this.setInterval();
    }
    Home.prototype.setInterval = function () {
        var _this = this;
        var navs = $(".button--space_content > .button--space");
        setInterval(function (e) {
            var cur = $(".button--space_content > .active").index();
            var nxt = (cur + 1) % navs.length;
            navs.eq(cur).removeClass("active");
            navs.eq(nxt).addClass("active");
            _this.showLottiewhenActive();
        }, 5000);
    };
    Home.prototype.showLottiewhenActive = function () {
        if (this.$shopperSpace.hasClass("active")) {
            this.$riderMap.hide();
            this.$hubMap.hide();
            this.$shopperMap.show();
            this.lottieHelperShopper.play();
        }
        if (this.$riderSpace.hasClass("active")) {
            this.lottieHelperRider.play();
            this.$shopperMap.hide();
            this.$hubMap.hide();
            this.$riderMap.show();
            this.lottieHelperRider.play();
        }
        else if (this.$hubSpace.hasClass("active")) {
            this.$shopperMap.hide();
            this.$riderMap.hide();
            this.$hubMap.show();
            this.lottieHelperHub.play();
        }
    };
    Home.prototype.showLottieOnclick = function () {
        var _this = this;
        if (this.$shopperSpace.hasClass("active")) {
            this.$riderMap.hide();
            this.$hubMap.hide();
            this.$shopperMap.show();
            this.lottieHelperShopper.play();
        }
        this.$shopperSpace.on("click", function (e) {
            _this.lottieHelperShopper.play();
            _this.$shopperMap.show();
            _this.$riderMap.hide();
            _this.$hubMap.hide();
            $(".button--space").removeClass("active");
            _this.$shopperSpace.addClass("active");
        });
        this.$riderSpace.on("click", function (e) {
            _this.lottieHelperRider.play();
            _this.$riderMap.show();
            _this.$shopperMap.hide();
            _this.$hubMap.hide();
            $(".button--space").removeClass("active");
        });
        this.$hubSpace.on("click", function (e) {
            _this.lottieHelperHub.play();
            _this.$hubMap.show();
            _this.$shopperMap.hide();
            _this.$riderMap.hide();
            $(".button--space").removeClass("active");
            _this.$hubSpace.addClass("active");
        });
        var $windowsScreen = $(window);
        if ($windowsScreen.width() > 620) {
            $('.wrapper--slide').slick({
                infinite: true,
                arrows: true,
                prevArrow: $(".arrow--back_delivery"),
                nextArrow: $(".arrow--forward_delivery"),
                slidesToShow: 1,
                slidesToScroll: 1
            });
        }
    };
    return Home;
}());
$(function () {
    new Home();
});

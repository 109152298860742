require('slick-carousel');
var SlickSliderHelper = /** @class */ (function () {
    function SlickSliderHelper(inputSelector, slickConf) {
        this.input = inputSelector;
        this.slickConf = slickConf;
        this.bindSlickSlider();
    }
    SlickSliderHelper.prototype.bindSlickSlider = function () {
        this.input.slick(this.slickConf);
    };
    SlickSliderHelper.prototype.getInput = function () {
        return this.input;
    };
    return SlickSliderHelper;
}());
export default SlickSliderHelper;
